import React from 'react';
import { StepCircle, StepText, StepWrapper, StepImage } from './StepStyles';

interface StepProps {
    index: number;
    $enabled: boolean;
    completed: boolean;
    onClick: () => void;
    position: { top: string; left: string };
}

/**
 * Step component renders a single step in a series of steps, indicating whether it is completed or active.
 *
 * @param {StepProps} props - The props for the Step component.
 * @param {number} props.index - The index of the step.
 * @param {boolean} props.$enabled - Indicates if the step is enabled and clickable.
 * @param {boolean} props.completed - Indicates if the step is completed.
 * @param {() => void} props.onClick - Function to call when the step is clicked.
 * @param {{ top: string; left: string }} props.position - The position of the step in terms of `top` and `left` CSS properties.
 * @returns {JSX.Element} The rendered Step component.
 */
const Step: React.FC<StepProps> = ({
    index,
    $enabled,
    completed,
    onClick,
    position,
}) => (
    <StepWrapper
        data-testid={`step-${index}`}
        id={`step-${index}`}
        $enabled={$enabled}
        $completed={completed}
        $top={position.top}
        $left={position.left}
    >
        {completed ? (
            <StepImage
                src={`${window.location.origin}/images/various/completed_step.svg`}
                alt="Completed"
            />
        ) : (
            <StepCircle
                $enabled={$enabled}
                onClick={$enabled ? onClick : undefined}
            >
                <StepText>{index + 1}</StepText>
            </StepCircle>
        )}
    </StepWrapper>
);

export default React.memo(Step);
