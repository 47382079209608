import { useState, useCallback } from 'react';

/**
 * Custom hook to manage the state of a modal.
 *
 * @returns {{
 *   isModalOpen: boolean,
 *   openModal: () => void,
 *   closeModal: () => void,
 * }} An object containing:
 * - `isModalOpen`: A boolean indicating if the modal is open.
 * - `openModal`: A function to open the modal.
 * - `closeModal`: A function to close the modal.
 */
export const useModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = useCallback(() => setIsModalOpen(true), []);

    const closeModal = useCallback(() => setIsModalOpen(false), []);

    return { isModalOpen, openModal, closeModal };
};
