import React, { useRef, useCallback } from 'react';
import { predefinedMarkers } from '../utils/positions';
import gsap from 'gsap';

/**
 * Interface representing the return value of the useAnimations hook.
 *
 * @interface UseAnimationsReturn
 * @property {React.RefObject<SVGPathElement>[]} pathRefs - Array of references to SVG path elements.
 * @property {React.RefObject<HTMLImageElement>} flagRef - Reference to the flag image element.
 * @property {(index: number) => void} animatePath - Function to animate a specific path based on its index.
 * @property {() => void} animateFlag - Function to animate the flag.
 */
interface UseAnimationsReturn {
    pathRefs: React.RefObject<SVGPathElement>[];
    flagRef: React.RefObject<HTMLImageElement>;
    animatePath: (index: number) => void;
    animateFlag: () => void;
}

/**
 * Custom hook for handling animations of SVG paths and a flag image.
 *
 * @returns {UseAnimationsReturn} An object containing references and functions to control animations.
 */
export const useAnimations = (): UseAnimationsReturn => {
    const flagRef = useRef<HTMLImageElement | null>(null);
    const pathRefs = useRef(
        predefinedMarkers.map(() => React.createRef<SVGPathElement>())
    );

    /**
     * Plays a sound from the provided URL.
     *
     * @param {string} soundUrl - The URL of the sound file to play.
     */
    const playSound = useCallback((soundUrl: string) => {
        const audio = new Audio(soundUrl);
        audio.play().catch((error) => {
            console.error('Audio play failed:', error);
        });
    }, []);

    /**
     * Animates the path at the specified index.
     *
     * @param {number} index - The index of the path to animate.
     */
    const animatePath = useCallback(
        (index: number) => {
            const pathRef = pathRefs.current[index].current;
            if (pathRef) {
                const dashLength = 8;
                const gapLength = 8;
                const totalLength = pathRef.getTotalLength();
                const dashArray = `${dashLength} ${gapLength}`;

                // Set initial state with opacity 0
                gsap.set(pathRef, {
                    strokeDasharray: dashArray,
                    strokeDashoffset: totalLength,
                    opacity: 0,
                });

                // Animate strokeDashoffset to reveal the path and fade in
                gsap.to(pathRef, {
                    strokeDasharray: dashArray,
                    strokeDashoffset: 0,
                    duration: 3,
                    ease: 'linear',
                });

                gsap.to(pathRef, {
                    duration: 0.3,
                    opacity: 1,
                    ease: 'linear',
                });
            }
        },
        [pathRefs]
    );

    /**
     * Animates the flag image element.
     */
    const animateFlag = useCallback(() => {
        if (flagRef?.current) {
            gsap.set(flagRef.current, { opacity: 0, y: 20 });
            gsap.to(flagRef.current, { opacity: 1, y: 0, duration: 3 });

            const soundUrl = `${window.location.origin}/sounds/sound.mp3`;
            playSound(soundUrl);
        }
    }, [playSound, flagRef]);

    return {
        pathRefs: pathRefs.current,
        flagRef,
        animatePath,
        animateFlag,
    };
};
