import styled, { keyframes } from 'styled-components';

const borderAnimation = keyframes`
    0% {
        border-image: linear-gradient(
            to top right,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    25% {
        border-image: linear-gradient(
            to bottom right,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    50% {
        border-image: linear-gradient(
            to bottom left,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    75% {
        border-image: linear-gradient(
            to top left,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    100% {
        border-image: linear-gradient(
            to top right,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
`;

const backgroundLanding = `${window.location.origin}/images/backgrounds/background_landing.svg`;

export const Wrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-image: url(${backgroundLanding});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;

    @media (max-width: 1024px) {
        padding: 4rem 1rem 4rem;
    }

    @media (min-width: 1024px) {
        padding: 4rem 2rem;
    }

    @media (min-width: 2560px) {
        justify-content: center;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 95%;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const Column = styled.div`
    flex: 1;
    text-align: left;
    display: block;
    width: 100%;
    margin-right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;

    @media (min-width: 520px) and (max-width: 1024px) {
        width: 80%;
        align-self: flex-start;
    }

    @media (min-width: 1025px) {
        margin-right: 5rem;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const TheAbbott = styled.span`
    color: #fff;
    font-family: 'Brandon Grotesque Bold';
    font-size: 3.0625rem;
    font-style: normal;
    font-weight: 450;
    line-height: 130%;
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: inline-block;
    word-wrap: break-word;

    @media (max-width: 1024px) {
        font-size: 200%;
        margin-bottom: 0;
        line-height: 1;
    }
`;

export const CustomerPledge = styled.span`
    color: #ffd100;
    font-family: 'Brandon Grotesque Bold';
    font-size: 3.8125rem;
    font-style: normal;
    font-weight: 450;
    line-height: 4rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    display: inline-block;
    word-wrap: break-word;

    @media (max-width: 1024px) {
        font-size: 320%;
        line-height: 100%;
    }
`;

export const Subtitle = styled.h2`
    align-self: stretch;
    color: #fff;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
    margin-bottom: 2rem;

    @media (max-width: 1024px) {
        font-size: 90%;
    }
`;

export const Description = styled.p`
    align-self: stretch;
    color: #fff;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 390;
    line-height: 130%;
    margin-bottom: 2rem;

    @media (max-width: 1024px) {
        font-size: 100%;
        margin-bottom: 2rem;
    }
`;

export const Hashtag = styled.div`
    display: flex;
    margin-top: 2rem;
    text-transform: uppercase;

    @media (max-width: 1024px) {
        margin-top: 0;
    }
`;

export const HashtagAbbott = styled.span`
    color: #fff;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 390;
    line-height: 120%;

    @media (max-width: 1024px) {
        font-size: 80%;
    }
`;

export const HashtagProud = styled.span`
    color: #fff;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    @media (max-width: 1024px) {
        font-size: 80%;
    }
`;

export const FormTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1024px) {
        margin: 2rem 0 2rem;
        width: 100%;
    }
`;

export const FormBox = styled.div`
    display: flex;
    max-width: 30rem;
    padding: 2rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    background: #fff;
    gap: 1.25rem;
    position: relative;
    box-sizing: border-box;

    &::before {
        content: '';
        position: absolute;
        top: -1rem;
        right: -1rem;
        bottom: -1rem;
        left: -1rem;
        pointer-events: none;
        border: 6px solid transparent;
        border-image: linear-gradient(
                to top right,
                rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
            )
            1;
        animation: ${borderAnimation} 5s linear infinite;
    }

    @media (max-width: 1024px) {
        width: 100%;
        padding: 1.25rem;
    }
`;

export const FormGroup = styled.div`
    margin-bottom: 0.9375rem;
    width: 100%;

    .error-message {
        height: 2.25rem;
        margin-top: 0.5rem;
        align-self: start;
        color: #e4002b;
        font-family: 'Brandon Grotesque Regular';
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 390;
        line-height: 140%;
    }
`;

export const Input = styled.input<{ $hasError: boolean }>`
    width: 100%;
    padding: 0.75rem 1rem;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
    border-radius: 0.25rem;
    border: 2px solid ${({ $hasError }) => ($hasError ? '#E4002B' : '#D9D9D6')};
    box-sizing: border-box;
    font-size: 1.25rem;
    font-family: 'Brandon Grotesque Regular';
    font-weight: 420;
    line-height: 100%;
    color: #002a3a;

    &:hover {
        border-color: #009cde;
    }

    &:active {
        border-color: #002a3a;
    }

    &::placeholder {
        color: #63666a;
        font-family: 'Brandon Grotesque Regular';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 420;
        line-height: 100%;
    }

    @media (max-width: 1024px) {
        font-size: 100%;
        padding: 0.75rem;

        &::placeholder {
            font-size: 100%;
        }
    }
`;

export const Select = styled.select<{ $hasError: boolean }>`
    width: 100%;
    padding: 0.75rem 1rem;
    height: 3.5rem;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
    border-radius: 0.25rem;
    border: 2px solid ${({ $hasError }) => ($hasError ? '#E4002B' : '#D9D9D6')};
    box-sizing: border-box;
    font-size: 1.25rem;
    font-family: 'Brandon Grotesque Regular';
    font-weight: 420;
    line-height: 100%;
    color: #002a3a;

    &:hover {
        border-color: #009cde;
    }

    &:active {
        border-color: #002a3a;
    }

    option:disabled {
        color: #63666a;
    }

    @media (max-width: 1024px) {
        font-size: 100%;
        padding: 0.75rem;
    }
`;

export const ErrorContainer = styled.div`
    height: 1.25rem;
    display: flex;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    color: red;
    font-size: 0.875rem;
    text-align: left;
`;

export const AbbottProudChallenge = styled.h3`
    color: #ffd100;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.9375rem;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    align-self: flex-start;
    text-transform: uppercase;

    @media (max-width: 1024px) {
        font-size: 125%;
        line-height: 120%;
        margin-bottom: 0.5rem;
    }
`;

export const PledgeToContainer = styled.div`
    display: flex;
    margin-bottom: 2rem;
    align-self: flex-start;
`;

export const PledgeTo = styled.span`
    color: #fff;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: left;
    align-self: flex-start;
    text-transform: uppercase;
    margin-right: 0.5rem;

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;

export const PlayTheGame = styled.span`
    color: #fff;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 420;
    line-height: 120%;
    text-align: left;
    align-self: flex-start;
    text-transform: uppercase;

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;

export const FormMessage = styled.div`
    color: #63666a;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 390;
    line-height: 120%;
    text-align: left;
    align-self: stretch;

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;

export const Terms = styled.p`
    align-self: stretch;
    color: #63666a;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 390;
    line-height: 120%;

    @media (max-width: 1024px) {
        font-size: 85%;
        text-align: left;
    }
`;

export const CommitText = styled.p`
    align-self: stretch;
    color: #002a3a;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 450;
    line-height: 130%;
    text-transform: uppercase;

    @media (max-width: 1024px) {
        font-size: 110%;
        margin-bottom: 1rem;
    }
`;

export const TermsLink = styled.button`
    background: none;
    border: none;
    color: #009cde;
    cursor: pointer;
    font-family: 'Brandon Grotesque Regular';
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 450;
    line-height: 120%;
    text-decoration: underline;
    padding: 0;

    &:hover {
        text-decoration: none;
    }
`;

export const TermsContainer = styled.div`
    overflow-y: auto;
    color: #002a3a;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 390;
    line-height: 120%;
    text-align: start;

    @media (max-width: 1024px) {
        font-size: 100%;
    }

    a {
        color: #009cde;
        font-weight: 450;
        line-height: 120%;
        text-decoration-line: underline;
    }

    li {
        list-style: inside;
    }
`;
