import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from './useLocalStorage';
import { useUserApi } from './useUserApi';
import { User } from '../utils/types';

export interface FormState {
    language: string;
    email: string;
    isFormTouched: boolean;
    isButtonDisabled: boolean;
    errors: { email?: string };
}

/**
 * Custom hook to manage login form state and operations.
 *
 * @param {() => void} onLogin - Callback function to be called upon successful login.
 * @returns {{
 *  formState: FormState,
 *  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
 *  handleLanguageChange: (language: string) => void,
 *  handleSubmit: () => Promise<void>,
 *  isLoading: boolean
 * }} Object containing form state, handlers, and loading state.
 */
export const useLoginForm = (onLogin: () => void) => {
    const [formState, setFormState] = useState<FormState>({
        language: 'en',
        email: '',
        isFormTouched: false,
        isButtonDisabled: true,
        errors: {},
    });

    const { setLocalStorageItem } = useLocalStorage<User>('user', {} as User);
    const { t } = useTranslation();
    const { findUserByEmail, updateUser, isLoading } = useUserApi();

    /**
     * Validates the email field.
     *
     * @param {string} email - The email to validate.
     * @returns {{ isValid: boolean, newErrors: { [key: string]: string } }} An object containing validation result and errors.
     */
    const validateEmail = useCallback(
        (email: string) => {
            const newErrors: { [key: string]: string } = {};

            if (!email) {
                newErrors.email = t('login.error.emailRequired');
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
            ) {
                newErrors.email = t('login.error.invalidEmail');
            }

            const isValid = Object.keys(newErrors).length === 0;
            return { isValid, newErrors };
        },
        [t]
    );

    /**
     * Updates form state and errors based on the given email.
     *
     * @param {string} email - The email to update in the form state.
     */
    const updateFormState = useCallback(
        (email: string) => {
            const sanitizedEmail = email.trim().toLowerCase();
            const { isValid, newErrors } = validateEmail(sanitizedEmail);

            setFormState((prevState) => ({
                ...prevState,
                email: sanitizedEmail,
                isFormTouched: true,
                isButtonDisabled: !isValid,
                errors: newErrors,
            }));
        },
        [validateEmail]
    );

    /**
     * Handles the change event for the email input field.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - The change event for the email input.
     */
    const handleEmailChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const email = e.target.value;
            updateFormState(email);
        },
        [updateFormState]
    );

    /**
     * Handles the change event for the language selection.
     *
     * @param {string} language - The selected language.
     */
    const handleLanguageChange = useCallback((language: string) => {
        setFormState((prevState) => ({
            ...prevState,
            language,
        }));
    }, []);

    /**
     * Handles the submit event for the login form.
     */
    const handleSubmit = useCallback(async () => {
        const { email, language } = formState;
        const sanitizedEmail = email.trim().toLowerCase();
        const { isValid, newErrors } = validateEmail(sanitizedEmail);

        setFormState((prevState) => ({
            ...prevState,
            isFormTouched: true,
            errors: newErrors,
        }));

        if (!isValid) return;

        try {
            const existingUserResponse = await findUserByEmail(sanitizedEmail);

            if (existingUserResponse?.error) {
                console.error(
                    `Error finding user: ${existingUserResponse.error}`
                );
                return;
            }

            let updatedUser: User | null = null;

            if (!existingUserResponse?.data) {
                const newUser: User = {
                    id: sanitizedEmail,
                    email: sanitizedEmail,
                    language,
                };
                const newUserResponse = await updateUser(newUser);

                if (newUserResponse?.error) {
                    console.error(
                        `Error creating user: ${newUserResponse.error}`
                    );
                    return;
                }

                updatedUser = newUser;
            } else {
                let existingUser = existingUserResponse.data;

                // change typeof
                const tempIndex = parseInt(existingUser.currentQuestionIndex!);

                // compare current index to answers (array length) to detect data mismatch
                if (tempIndex > existingUser.answers?.length!) {
                    // data mismatch: reset answers and current index
                    const resetedData = { ...existingUser, answers: [{question: 'null', answer: 'null'}], currentQuestionIndex: '0' };
                    existingUser = resetedData;
                } 

                if (existingUser?.language !== language) {
                    existingUser.language = language;
                    const updateUserResponse = await updateUser(existingUser);

                    if (updateUserResponse?.error) {
                        console.error(
                            `Error updating user: ${updateUserResponse.error}`
                        );
                        return;
                    }
                }
                updatedUser = existingUser;
            }

            if (updatedUser) {
                const userToStore = {
                    ...updatedUser,
                    language: updatedUser.language,
                };
                setLocalStorageItem(userToStore);
                onLogin();
            } else {
                console.error('Failed to update or create user');
            }
        } catch (error) {
            console.error('Error handling submit:', error);
        }
    }, [
        formState,
        validateEmail,
        findUserByEmail,
        updateUser,
        onLogin,
        setLocalStorageItem,
    ]);

    return {
        formState,
        handleEmailChange,
        handleLanguageChange,
        handleSubmit,
        isLoading,
    };
};
