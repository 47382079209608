import React from 'react';
import { ButtonWrapper } from './ButtonStyles';

type ButtonProps = {
    /**
     * Function to call when the button is clicked.
     */
    onClick: () => void;

    /**
     * The content of the button.
     */
    children: React.ReactNode;

    /**
     * Whether the button is disabled.
     */
    disabled?: boolean;

    /**
     * Additional class names to apply to the button.
     */
    className?: string;
};

/**
 * A reusable button component.
 *
 * @param {ButtonProps} props - The props for the button.
 * @param {() => void} props.onClick - Function to call when the button is clicked.
 * @param {React.ReactNode} props.children - The content of the button.
 * @param {boolean} [props.disabled] - Whether the button is disabled.
 * @param {string} [props.className] - Additional class names to apply to the button.
 * @returns {JSX.Element} The rendered button component.
 */
const Button: React.FC<ButtonProps> = ({
    onClick,
    children,
    disabled,
    className,
}) => {
    return (
        <ButtonWrapper
            onClick={onClick}
            disabled={disabled}
            className={className}
        >
            {children}
        </ButtonWrapper>
    );
};

export default React.memo(Button);
