import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    overflow-y: auto;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: 769px) {
        width: 80%;
    }

    @media (min-width: 769px) {
        flex-direction: row;
    }
`;

export const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    @media (min-width: 769px) {
        align-items: flex-start;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;
    margin: 1rem 4rem 1rem 4rem;
`;

export const Image = styled.img`
    max-width: 15rem;
`;

export const PersonaTypeText = styled.div<{ $personaType: string }>`
    position: absolute;
    top: 74%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ $personaType }) =>
        $personaType === 'creator' || $personaType === 'facilitator'
            ? '#000'
            : '#FFF'};
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-size: 75%;
    font-style: normal;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;
`;

export const Message = styled.p`
    color: #63666a;
    font-family: 'Brandon Grotesque Regular';
    font-size: 100%;
    font-style: normal;
    font-weight: 420;
    line-height: 120%;
    text-align: center;
    width: 100%;

    @media (min-width: 769px) {
        text-align: left;
    }
`;

export const Caption = styled.p`
    color: #63666a;
    font-family: 'Brandon Grotesque Regular';
    font-size: 80%;
    font-style: normal;
    font-weight: 420;
    line-height: 120%;
    margin-top: 2rem;
    text-align: center;
    width: 100%;

    @media (min-width: 769px) {
        text-align: left;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 1rem;

    @media (min-width: 520px) {
        flex-direction: row;
        gap: 2rem;
    }
`;
