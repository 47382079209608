import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Wrapper,
    Container,
    Logo,
    ProgressBarContainer,
    ProgressBarFilled,
    LoadingText,
} from './LoadingStyles';

interface LoadingProps {
    $duration: number;
}

/**
 * Loading component displays a loading screen with a progress bar, logo, and loading text.
 *
 * @param {LoadingProps} props - The props for the Loading component.
 * @param {number} props.$duration - The duration for the progress bar animation.
 * @returns {JSX.Element} The rendered Loading component.
 */
const Loading: React.FC<LoadingProps> = ({ $duration }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container>
                <Logo
                    src={`${window.location.origin}/images/logos/Abbott_logo.svg`}
                    alt="Logo"
                    data-testid="logo"
                />
                <ProgressBarContainer
                    role="progressbar"
                    data-testid="progressbar-container"
                >
                    <ProgressBarFilled
                        $duration={$duration}
                        data-testid="progressbar-filled"
                        data-duration={$duration}
                    />
                </ProgressBarContainer>
                <LoadingText>{t('quiz.loading')}</LoadingText>
            </Container>
        </Wrapper>
    );
};

export default React.memo(Loading);
