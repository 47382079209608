import { useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json';
import esTranslation from './es/translation.json';
import jaTranslation from './ja/translation.json';
import zhTranslation from './zh/translation.json';
import nlTranslation from './nl/translation.json';
import frTranslation from './fr/translation.json';
import deTranslation from './de/translation.json';
import itTranslation from './it/translation.json';
import ptTranslation from './pt/translation.json';
import ruTranslation from './ru/translation.json';
import viTranslation from './vi/translation.json';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { User } from '../utils/types';

const defaultLanguage = 'en';

/**
 * Translation resources for different languages.
 */
const resources = {
    en: { translation: enTranslation },
    es: { translation: esTranslation },
    ja: { translation: jaTranslation },
    zh: { translation: zhTranslation },
    nl: { translation: nlTranslation },
    fr: { translation: frTranslation },
    de: { translation: deTranslation },
    it: { translation: itTranslation },
    pt: { translation: ptTranslation },
    ru: { translation: ruTranslation },
    vi: { translation: viTranslation },
};

const useInitializeI18n = () => {
    const { getLocalStorageItem } = useLocalStorage<User>('user', {} as User);
    const user = getLocalStorageItem();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        i18n.use(initReactI18next)
            .init({
                resources,
                lng: user?.language || defaultLanguage,
                fallbackLng: defaultLanguage,
                interpolation: {
                    escapeValue: false,
                },
            })
            .then(() => {
                setIsInitialized(true);
            });
    }, [user]);

    return isInitialized;
};

export default useInitializeI18n;
