import React from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
    isAuthenticated: boolean;
    children: React.ReactNode;
}

/**
 * PrivateRoute component that conditionally renders children if the user is authenticated,
 * otherwise redirects to the login page.
 *
 * @param {PrivateRouteProps} props - The props for the PrivateRoute component.
 * @param {boolean} props.isAuthenticated - Indicates if the user is authenticated.
 * @param {React.ReactNode} props.children - The components to render if the user is authenticated.
 * @returns {JSX.Element} The rendered PrivateRoute component.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({
    isAuthenticated,
    children,
}) => {
    return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
