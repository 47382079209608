import React, { useEffect, useCallback, useRef } from 'react';
import Modal from '../Modal/Modal';
import MultipleChoiceQuestion from '../MultipleChoiceQuestion/MultipleChoiceQuestion';

interface QuestionModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    question: string;
    options: { [key: string]: string };
    onAnswer: (answer: string) => void;
    showFeedback: boolean;
    feedbackMessage: string;
    onNextQuestion: () => void;
    nextButtonText: string;
    title: string;
    stepNumber: number;
}

/**
 * QuestionModal component renders a modal with a multiple-choice question and feedback.
 *
 * @param {QuestionModalProps} props - The props for the QuestionModal component.
 * @param {boolean} props.isOpen - Determines if the modal is open or closed.
 * @param {() => void} props.onRequestClose - Function to call when the modal is requested to be closed.
 * @param {string} props.question - The question to be displayed inside the modal.
 * @param {{ [key: string]: string }} props.options - The options for the multiple-choice question.
 * @param {(answer: string) => void} props.onAnswer - Function to handle the selected answer.
 * @param {boolean} props.showFeedback - Whether to show feedback after selecting an answer.
 * @param {string} props.feedbackMessage - The feedback message to display.
 * @param {() => void} props.onNextQuestion - Function to call when moving to the next question.
 * @param {string} props.nextButtonText - The text to display on the next question button.
 * @param {string} props.title - The title of the modal.
 * @param {number} props.stepNumber - The current step number of the quiz.
 * @returns {JSX.Element} The rendered QuestionModal component.
 */
const QuestionModal: React.FC<QuestionModalProps> = ({
    isOpen,
    onRequestClose,
    question,
    options,
    onAnswer,
    showFeedback,
    feedbackMessage,
    onNextQuestion,
    nextButtonText,
    title,
    stepNumber,
}) => {
    const modalContentRef = useRef<HTMLDivElement>(null);

    const handleNextQuestion = useCallback(() => {
        onNextQuestion();
    }, [onNextQuestion]);

    // Scroll to top when the modal is opened or updated
    useEffect(() => {
        if (isOpen && modalContentRef.current) {
            modalContentRef.current.scrollTop = 0;
        }
    }, [isOpen, question, showFeedback, stepNumber]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onRequestClose}
            title={title}
            noScroll
            centerAlign
        >
            <div style={{ overflowY: 'auto' }} ref={modalContentRef}>
                <MultipleChoiceQuestion
                    question={question}
                    options={options}
                    handleAnswer={onAnswer}
                    stepNumber={stepNumber}
                />
                {showFeedback && (
                    <div>
                        <h2>{feedbackMessage}</h2>
                        <button onClick={handleNextQuestion}>
                            {nextButtonText}
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default React.memo(QuestionModal);
