import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useNavigate,
} from 'react-router-dom';
import Welcome from './components/Welcome/Welcome';
import Login from './components/Login/Login';
import Quiz from './components/Quiz/Quiz';
import ErrorScreen from './components/ErrorScreen/ErrorScreen';
import Completed from './components/Completed/Completed';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import useInitializeI18n from './i18n/useInitializeI18n';
import Loading from './components/Loading/Loading';

/**
 * The main application component.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const App: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    /**
     * Handles user login by setting the authentication state.
     */
    const handleLogin = () => {
        setIsAuthenticated(true);
    };

    const isInitialized = useInitializeI18n();

    if (!isInitialized) {
        return <Loading $duration={3000} />;
    }

    return (
        <Router>
            <BlockNavigation />
            <Routes>
                <Route path="/error" element={<ErrorScreen />} />
                <Route
                    path="/login"
                    element={<Login onLogin={handleLogin} />}
                />
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route
                    path="/welcome"
                    element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <Welcome />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/quiz"
                    element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <Quiz />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/completed"
                    element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <Completed />
                        </PrivateRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </Router>
    );
};

/**
 * Component to block navigation using history.pushState and popstate event.
 */
const BlockNavigation: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.onpopstate = function () {
            navigate('/login');
        };

        return () => {
            window.onpopstate = null;
        };
    }, [navigate]);

    return null;
};

export default App;
