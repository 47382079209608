import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

if (!SECRET_KEY) {
    throw new Error(
        'Secret key is not defined. Please set REACT_APP_SECRET_KEY in your environment variables.'
    );
}

/**
 * Encrypts data using AES encryption.
 *
 * @param {any} data - The data to encrypt.
 * @returns {string} The encrypted data as a string.
 */
const encryptData = (data: any): string | null => {
    try {
        return CryptoJS.AES.encrypt(
            JSON.stringify(data),
            SECRET_KEY
        ).toString();
    } catch (error) {
        console.error('Error encrypting data:', error);
        return null;
    }
};

/**
 * Decrypts data that was encrypted using AES encryption.
 *
 * @param {string} ciphertext - The encrypted data as a string.
 * @returns {any | null} The decrypted data, or null if decryption fails.
 */
const decryptData = (ciphertext: string): any | null => {
    try {
        const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error('Error decrypting data:', error);
        return null;
    }
};

/**
 * Hashes data using SHA-256 hashing algorithm.
 *
 * @param {any} data - The data to hash.
 * @returns {string} The hashed data as a string.
 */
const hashData = (data: any): string | null => {
    try {
        const dataString = JSON.stringify(data) + SECRET_KEY;
        const utf8DataString = CryptoJS.enc.Utf8.parse(dataString);
        return CryptoJS.SHA256(utf8DataString).toString(CryptoJS.enc.Hex);
    } catch (error) {
        console.error('Error decrypting data:', error);
        return null;
    }
};

/**
 * Custom hook to provide encryption, decryption, and hashing utilities.
 *
 * @param {boolean} enabled - Flag to toggle encryption utilities on or off.
 * @returns {{
 *   encrypt: (data: any) => string | any,
 *   decrypt: (ciphertext: string) => any | null,
 *   hash: (data: any) => string | any
 * }} An object containing encrypt, decrypt, and hash functions.
 */
export const useEncryption = (enabled: boolean) => {
    const noop = (data: any) => data;

    return {
        encrypt: enabled ? encryptData : noop,
        decrypt: enabled ? decryptData : noop,
        hash: enabled ? hashData : noop,
    };
};
