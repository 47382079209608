import styled from 'styled-components';

export const StepWrapper = styled.div<{
    $enabled: boolean;
    $completed: boolean;
    $top: string;
    $left: string;
}>`
    position: absolute;
    top: ${(props) => props.$top};
    left: ${(props) => props.$left};
    display: ${(props) =>
        props.$enabled || props.$completed ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: auto;
    z-index: 1;
`;

export const StepCircle = styled.div<{ $enabled: boolean }>`
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: ${(props) => (props.$enabled ? '#7CCC6C' : '#ccc')};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => (props.$enabled ? 'pointer' : 'not-allowed')};
    position: relative;
    font-size: 1.5625rem;
    color: #002a3a;
    margin: 20px 0;
    border: 3px solid #222731;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
    font-family: 'Brandon Grotesque Bold';
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    line-height: 130%;

    &:hover {
        background-color: #d9d9d6;
        border: 3px solid #63666a;
    }
`;

export const StepText = styled.div`
    color: #002a3a;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
`;

export const StepImage = styled.img`
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
    margin: 20px 0;
`;
