import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import Button from '../Button/Button';
import { useLoginForm } from '../../hooks/useLoginForm';
import { useAuthRedirect } from '../../hooks/useAuthRedirect';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
    Wrapper,
    LanguageSelectorWrapper,
    Content,
    FormBox,
    FormGroup,
    Title,
    Input,
} from './LoginStyles';

interface LoginProps {
    onLogin: () => void;
}

/**
 * Login component renders the login form with language selector and handles login functionality.
 *
 * @param {LoginProps} props - The props for the Login component.
 * @param {() => void} props.onLogin - Function to call when the user logs in successfully.
 * @returns {JSX.Element} The rendered Login component.
 */
const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const { t } = useTranslation();
    const { clearAllLocalStorageItems } = useLocalStorage(
        'dummyKey',
        '',
        false
    );
    const [resetLanguage, setResetLanguage] = useState(false);

    const [redirectTrigger, setRedirectTrigger] = useState(false);
    const { formState, handleEmailChange, handleLanguageChange, handleSubmit } =
        useLoginForm(() => {
            onLogin();
            setRedirectTrigger(true);
        });

    useAuthRedirect(redirectTrigger);

    useEffect(() => {
        clearAllLocalStorageItems();
        setResetLanguage(true);
    }, [clearAllLocalStorageItems]);

    /**
     * Handles the key down event for the input field to submit the form on Enter key press.
     *
     * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event.
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <Wrapper>
            <LanguageSelectorWrapper>
                <LanguageSelector
                    onLanguageChange={handleLanguageChange}
                    resetLanguage={resetLanguage}
                    onResetComplete={() => setResetLanguage(false)}
                />
            </LanguageSelectorWrapper>
            <Content>
                <FormBox>
                    <Title>{t('login.title')}</Title>
                    <FormGroup>
                        <Input
                            type="email"
                            placeholder={t('login.emailPlaceholder')}
                            value={formState.email}
                            onChange={handleEmailChange}
                            onKeyDown={handleKeyDown}
                            $hasError={!!formState.errors.email}
                        />
                        <div className="error-message">
                            {formState.isFormTouched &&
                                formState.errors.email &&
                                formState.errors.email}
                        </div>
                    </FormGroup>
                    <Button
                        onClick={handleSubmit}
                        disabled={formState.isButtonDisabled}
                    >
                        {t('login.submitButton')}
                    </Button>
                </FormBox>
            </Content>
        </Wrapper>
    );
};

export default Login;
