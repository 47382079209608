import styled, { keyframes } from 'styled-components';

interface PopupProps {
    $duration: number;
}

// Keyframes for the appear and disappear animations
const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

const disappearToTop = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
`;

export const Popup = styled.div<PopupProps>`
    display: flex;
    max-width: 39.25rem;
    width: 90%;
    padding: 2.5rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.25rem;
    background: #fff;
    box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.2),
        0px 24px 38px 0px rgba(0, 0, 0, 0.14),
        0px 12px 56px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    animation: ${appearFromTop} 0.5s forwards,
        ${disappearToTop} 0.5s forwards ${({ $duration }) => $duration - 500}ms;
`;

export const Title = styled.h2`
    color: #002a3a;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-transform: uppercase;

    @media (max-width: 445px) {
        font-size: 145%;
        width: 100%;
        word-wrap: break-word;
    }
`;

export const Description = styled.div`
    color: #002a3a;
    text-align: center;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 420;
    line-height: 120%;
`;
