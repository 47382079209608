import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StepList from '../StepList/StepList';
import QuestionModal from '../QuestionModal/QuestionModal';
import FeedbackPopup from '../FeedbackPopup/FeedbackPopup';
import {
    Wrapper,
    BackgroundImage,
    backgroundLanding,
    TitleContainer,
    Title,
    Subtitle,
    AbbottProudContainer,
    AbbottPart,
    ProudPart,
    AbbottLogo,
    Overlay,
    HeaderContainer,
} from './QuizStyles';
import { useQuizLogic } from '../../hooks/useQuizLogic';
import { useAnimations } from '../../hooks/useAnimations';

type QuizSteps = {
    [key: number]: {
        question: string;
        options: { [key: string]: string };
        button: string;
    };
};

/**
 * Quiz component renders the quiz interface with questions, steps, and feedback.
 *
 * @returns {JSX.Element} The rendered Quiz component.
 */
const Quiz: React.FC = () => {
    const { t } = useTranslation();
    const {
        currentQuestionIndex,
        isModalOpen,
        showFeedback,
        feedbackMessages,
        handleAnswer,
        handleNextQuestion,
        setIsModalOpen,
        handleCloseFeedback,
    } = useQuizLogic();
    const { animateFlag } = useAnimations();

    const steps = useMemo(
        () => t('quiz.steps', { returnObjects: true }) as QuizSteps,
        [t]
    );

    const currentQuestionIndexSafe =
        currentQuestionIndex !== null ? currentQuestionIndex : 0;
    const stepNumber = currentQuestionIndexSafe + 1;
    const totalQuestions = useMemo(() => Object.keys(steps).length, [steps]);

    const currentQuestion = useMemo(
        () => steps[stepNumber],
        [steps, stepNumber]
    );

    const questionTitle = useMemo(
        () => `${t('quiz.question')} ${stepNumber}`,
        [t, stepNumber]
    );

    /**
     * Handles the click event for a step in the quiz.
     *
     * @param {number} step - The step number that was clicked.
     */
    const handleStepClick = useCallback(
        (step: number) => {
            const stepIndex = step - 1;
            if (
                stepIndex === currentQuestionIndexSafe ||
                step === totalQuestions + 1
            ) {
                setIsModalOpen(true);
            }
        },
        [currentQuestionIndexSafe, totalQuestions, setIsModalOpen]
    );

    /**
     * Triggers the flag animation.
     */
    const triggerAnimateFlag = useCallback(() => {
        animateFlag();
    }, [animateFlag]);

    const isFirstStep = currentQuestionIndexSafe === 0;

    return (
        <Wrapper>
            <BackgroundImage src={backgroundLanding} alt="Background" />
            <HeaderContainer $isFirstStep={isFirstStep}>
                <AbbottLogo
                    src={`${window.location.origin}/images/logos/Abbott_logo_full.svg`}
                    alt="Abbott Logo"
                />
                {isFirstStep ? (
                    <TitleContainer>
                        <Title>{t('quiz.title')}</Title>
                        <Subtitle>{t('quiz.subtitle')}</Subtitle>
                    </TitleContainer>
                ) : (
                    <Title>{t('quiz.title')}</Title>
                )}
            </HeaderContainer>
            <StepList
                currentQuestionIndex={currentQuestionIndexSafe}
                totalQuestions={totalQuestions}
                onStepClick={handleStepClick}
            />
            {showFeedback && (
                <>
                    <Overlay show={showFeedback} />
                    <FeedbackPopup
                        onClose={() => {
                            handleNextQuestion();
                            handleCloseFeedback();
                        }}
                        stepNumber={stepNumber}
                    />
                </>
            )}
            {currentQuestion && (
                <QuestionModal
                    isOpen={
                        isModalOpen && currentQuestionIndexSafe < totalQuestions
                    }
                    onRequestClose={() => setIsModalOpen(false)}
                    question={currentQuestion.question}
                    options={currentQuestion.options ?? {}}
                    onAnswer={(answerKey) => {
                        handleAnswer(answerKey);
                        triggerAnimateFlag();
                    }}
                    showFeedback={showFeedback}
                    feedbackMessage={feedbackMessages[stepNumber]}
                    onNextQuestion={handleNextQuestion}
                    nextButtonText={
                        stepNumber < totalQuestions
                            ? currentQuestion.button
                            : t('quiz.steps.6.button')
                    }
                    title={questionTitle}
                    stepNumber={stepNumber}
                />
            )}
            <AbbottProudContainer>
                <AbbottPart>#abbott</AbbottPart>
                <ProudPart>proud</ProudPart>
            </AbbottProudContainer>
        </Wrapper>
    );
};

export default Quiz;
