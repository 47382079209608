import styled from 'styled-components';

const backgroundLanding = `${window.location.origin}/images/backgrounds/background_landing.svg`;

export const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url(${backgroundLanding});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: flex-start;
    padding: 5rem;

    @media (max-width: 768px) {
        padding: 2rem;
        font-size: 3.0625rem;
    }
`;

export const ErrorTitle = styled.div`
    color: #ffd100;
    font-family: 'Brandon Grotesque Bold';
    font-size: 3.8125rem;
    font-style: normal;
    font-weight: 450;
    line-height: 130%;
    text-transform: uppercase;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }
`;

export const ButtonWrapper = styled.div``;
