import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from './useLocalStorage';
import { useUserApi } from './useUserApi';
import jobToPersonaMap from '../data/jobToPersonaMap';
import { User } from '../utils/types';

export interface FormState {
    name: string;
    organization: string;
    location: string;
    job: string;
    isFormTouched: boolean;
    isButtonDisabled: boolean;
    errors: { [key: string]: string };
    isLoading: boolean;
}

/**
 * Custom hook to manage the welcome form state and validation.
 *
 * @returns {{
 *  formState: FormState,
 *  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
 *  handleSubmit: () => Promise<void>,
 *  isLoading: boolean
 * }} Object containing form state, handlers, and loading state.
 */
export const useWelcomeForm = () => {
    const [formState, setFormState] = useState<FormState>({
        name: '',
        organization: '',
        location: '',
        job: '',
        isFormTouched: false,
        isButtonDisabled: true,
        errors: {},
        isLoading: false,
    });
    const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage<User>(
        'user',
        {} as User
    );
    const user = getLocalStorageItem();
    const { updateUser } = useUserApi();
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**
     * Validates form fields and returns validation results.
     *
     * @param {Object} fields - The form fields to validate.
     * @returns {{ isValid: boolean, newErrors: { [key: string]: string } }} Validation results.
     */
    const validateFields = useCallback(
        (fields: { [key: string]: string }) => {
            const newErrors: { [key: string]: string } = {};

            if (!fields.name) newErrors.name = t('welcome.form.error.name');
            if (!fields.organization)
                newErrors.organization = t('welcome.form.error.organization');
            if (!fields.location)
                newErrors.location = t('welcome.form.error.location');
            if (!fields.job) newErrors.job = t('welcome.form.error.job');

            const isValid = Object.keys(newErrors).length === 0;
            return { isValid, newErrors };
        },
        [t]
    );

    /**
     * Updates the form state with the provided field value.
     *
     * @param {string} name - The name of the field to update.
     * @param {string} value - The value to set for the field.
     */
    const updateFormState = useCallback(
        (name: string, value: string) => {
            const updatedFormState = {
                ...formState,
                [name]: value,
                isFormTouched: true,
            };
            const { isValid, newErrors } = validateFields({
                name: updatedFormState.name,
                organization: updatedFormState.organization,
                location: updatedFormState.location,
                job: updatedFormState.job,
            });

            setFormState({
                ...updatedFormState,
                isButtonDisabled: !isValid,
                errors: newErrors,
            });
        },
        [formState, validateFields]
    );

    /**
     * Handles changes to input fields and updates the form state.
     *
     * @param {React.ChangeEvent<HTMLInputElement | HTMLSelectElement>} e - The input change event.
     */
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = e.target;
            updateFormState(name, value);
        },
        [updateFormState]
    );

    /**
     * Handles form submission, updating the user data and navigating to the quiz.
     */
    const handleSubmit = useCallback(async () => {
        setFormState((prevState) => ({ ...prevState, isFormTouched: true }));
        const { isValid, newErrors } = validateFields({
            name: formState.name,
            organization: formState.organization,
            location: formState.location,
            job: formState.job,
        });

        if (!isValid) {
            setFormState((prevState) => ({ ...prevState, errors: newErrors }));
            return;
        }

        if (isValid && user) {
            setFormState((prevState) => ({ ...prevState, isLoading: true }));
            try {
                const personaType = jobToPersonaMap[formState.job];
                const updatedUser: User = {
                    ...user,
                    currentQuestionIndex: '0',
                    name: formState.name,
                    organization: formState.organization,
                    location: formState.location,
                    job: formState.job,
                    personaType,
                };

                const result = await updateUser(updatedUser);

                if (result && !result?.error) {
                    setLocalStorageItem(updatedUser);
                    navigate('/quiz');
                } else if (result?.error) {
                    console.error('Failed to update user:', result?.error);
                }
            } catch (error) {
                console.error('Failed to update user:', error);
            } finally {
                setTimeout(() => {
                    setFormState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                    }));
                }, 4000);
            }
        }
    }, [
        formState,
        validateFields,
        updateUser,
        setLocalStorageItem,
        navigate,
        user,
    ]);

    return {
        formState,
        handleChange,
        handleSubmit,
        isLoading: formState.isLoading,
    };
};
