import React from 'react';
import { useTranslation } from 'react-i18next';
import Congratulations from '../Congratulations/Congratulations';
import {
    Wrapper,
    HeaderContainer,
    AbbottLogo,
    Title,
    CongratulationsWrapper,
    PersonaTitle,
} from './CompletedStyles';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { User } from '../../utils/types';

/**
 * Completed component is displayed when the quiz is completed.
 * It shows the Abbott logo, a title, and a congratulations message based on the persona type.
 *
 * @returns {JSX.Element} The rendered Completed component.
 */
const Completed: React.FC = () => {
    const { t } = useTranslation();
    const { getLocalStorageItem } = useLocalStorage<User>('user', {} as User);
    const user = getLocalStorageItem();

    return (
        <Wrapper>
            <HeaderContainer>
                <AbbottLogo
                    src={`${window.location.origin}/images/logos/Abbott_logo_full.svg`}
                    alt="Abbott Logo"
                />
                <Title>{t('quiz.title')}</Title>
            </HeaderContainer>
            <CongratulationsWrapper>
                <PersonaTitle>
                    {t(
                        `congratulations.${user?.personaType?.toLowerCase()}.title`
                    )}
                </PersonaTitle>
                <Congratulations />
            </CongratulationsWrapper>
        </Wrapper>
    );
};

export default React.memo(Completed);
