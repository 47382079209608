/**
 * Sanitizes input to prevent injection attacks and other issues.
 *
 * @param input - The input to be sanitized.
 * @returns The sanitized input.
 */
export const sanitizeInput = (input: any): any => {
    if (typeof input === 'string') {
        return input.trim();
    } else if (Array.isArray(input)) {
        return input.map(sanitizeInput);
    } else if (typeof input === 'object' && input !== null) {
        const sanitizedObject: any = {};
        Object.keys(input).forEach((key) => {
            sanitizedObject[key] = sanitizeInput(input[key]);
        });
        return sanitizedObject;
    }
    return input;
};
