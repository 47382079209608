import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWelcomeForm } from '../../hooks/useWelcomeForm';
import { useModal } from '../../hooks/useModal';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import {
    Wrapper,
    Content,
    Column,
    FormGroup,
    Input,
    Select,
    ErrorContainer,
    ErrorMessage,
    FormBox,
    Title,
    TheAbbott,
    CustomerPledge,
    Subtitle,
    Description,
    FormTitleContainer,
    AbbottProudChallenge,
    PledgeToContainer,
    PledgeTo,
    PlayTheGame,
    Terms,
    TermsLink,
    TermsContainer,
    CommitText,
    Hashtag,
    HashtagAbbott,
    HashtagProud,
    FormMessage,
} from './WelcomeStyles';

/**
 * Welcome component renders the welcome screen with a form and modal.
 *
 * @returns {JSX.Element} The rendered Welcome component.
 */
const Welcome: React.FC = () => {
    const { t } = useTranslation();
    const { isModalOpen, openModal, closeModal } = useModal();
    const {
        formState: {
            name,
            organization,
            location,
            job,
            isLoading,
            isFormTouched,
            errors,
            isButtonDisabled,
        },
        handleChange,
        handleSubmit,
    } = useWelcomeForm();

    /**
     * Handles the key down event for the form to submit the form on Enter key press.
     *
     * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event.
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !isButtonDisabled && !isLoading) {
            handleSubmit();
        }
    };

    if (isLoading) {
        return <Loading $duration={3000} />;
    }

    return (
        <Wrapper data-testid="welcome-component">
            <Content>
                <Column>
                    <Title>
                        <TheAbbott>{t('welcome.title.theAbbott')}</TheAbbott>
                        <CustomerPledge>
                            {t('welcome.title.customerPledge')}
                        </CustomerPledge>
                    </Title>
                    <Subtitle>{t('welcome.subtitle')}</Subtitle>
                    <Description>{t('welcome.description')}</Description>
                    <Hashtag>
                        <HashtagAbbott>#Abbott</HashtagAbbott>
                        <HashtagProud>Proud</HashtagProud>
                    </Hashtag>
                </Column>
                <FormTitleContainer>
                    <AbbottProudChallenge>
                        {t('welcome.form.title.abbottProudChallenge')}
                    </AbbottProudChallenge>
                    <PledgeToContainer>
                        <PledgeTo>{t('welcome.form.title.pledgeTo')}</PledgeTo>
                        <PlayTheGame>
                            {t('welcome.form.title.playTheGame')}
                        </PlayTheGame>
                    </PledgeToContainer>
                    <FormBox onKeyDown={handleKeyDown}>
                        <FormMessage>
                            {t('welcome.form.fillOutForm')}
                        </FormMessage>
                        <FormGroup>
                            <Input
                                type="text"
                                placeholder={t('welcome.form.name')}
                                name="name"
                                value={name}
                                onChange={handleChange}
                                $hasError={isFormTouched && !!errors.name}
                            />
                            {isFormTouched && errors.name && (
                                <ErrorContainer>
                                    <ErrorMessage>{errors.name}</ErrorMessage>
                                </ErrorContainer>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Select
                                name="organization"
                                value={organization}
                                onChange={handleChange}
                                $hasError={
                                    isFormTouched && !!errors.organization
                                }
                            >
                                <option value="" disabled hidden>
                                    {t('welcome.form.organization')}
                                </option>
                                {Object.keys(
                                    t('welcome.form.organizations', {
                                        returnObjects: true,
                                    })
                                ).map((org) => (
                                    <option key={org} value={org}>
                                        {t(`welcome.form.organizations.${org}`)}
                                    </option>
                                ))}
                            </Select>
                            {isFormTouched && errors.organization && (
                                <ErrorContainer>
                                    <ErrorMessage>
                                        {errors.organization}
                                    </ErrorMessage>
                                </ErrorContainer>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Select
                                name="location"
                                value={location}
                                onChange={handleChange}
                                $hasError={isFormTouched && !!errors.location}
                            >
                                <option value="" disabled hidden>
                                    {t('welcome.form.location')}
                                </option>
                                {Object.keys(
                                    t('welcome.form.locations', {
                                        returnObjects: true,
                                    })
                                ).map((loc) => (
                                    <option key={loc} value={loc}>
                                        {t(`welcome.form.locations.${loc}`)}
                                    </option>
                                ))}
                            </Select>
                            {isFormTouched && errors.location && (
                                <ErrorContainer>
                                    <ErrorMessage>
                                        {errors.location}
                                    </ErrorMessage>
                                </ErrorContainer>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Select
                                name="job"
                                value={job}
                                onChange={handleChange}
                                $hasError={isFormTouched && !!errors.job}
                            >
                                <option value="" disabled hidden>
                                    {t('welcome.form.job')}
                                </option>
                                {Object.keys(
                                    t('welcome.form.jobs', {
                                        returnObjects: true,
                                    })
                                ).map((job) => (
                                    <option key={job} value={job}>
                                        {t(`welcome.form.jobs.${job}`)}
                                    </option>
                                ))}
                            </Select>
                            {isFormTouched && errors.job && (
                                <ErrorContainer>
                                    <ErrorMessage>{errors.job}</ErrorMessage>
                                </ErrorContainer>
                            )}
                        </FormGroup>
                        <Terms>
                            {t('welcome.form.terms.text1')}{' '}
                            <TermsLink onClick={openModal}>
                                <b>{t('welcome.form.terms.termsOfUse')}</b>
                            </TermsLink>{' '}
                            {t('welcome.form.terms.text2')}
                        </Terms>
                        <CommitText>{t('welcome.form.commitText')}</CommitText>
                        <Button
                            onClick={handleSubmit}
                            disabled={isLoading || isButtonDisabled}
                        >
                            {t('welcome.form.commitButton')}
                        </Button>
                    </FormBox>
                </FormTitleContainer>
            </Content>
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                title={t('welcome.form.terms.modal.title')}
            >
                <TermsContainer
                    dangerouslySetInnerHTML={{
                        __html: t('welcome.form.terms.modal.content'),
                    }}
                />
            </Modal>
        </Wrapper>
    );
};

export default React.memo(Welcome);
