import { useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { sanitizeInput } from '../utils/utils';
import useErrorScreen from './useErrorScreen';
import { User, Answer, ApiResponse } from '../utils/types';
import { useNavigate } from 'react-router-dom';

const uuid = process.env.REACT_APP_UUID;
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const isAxiosError = (err: any): err is AxiosError => {
    return axios.isAxiosError(err);
};

export const defaultUser: User = {
    id: null,
    email: null,
    language: 'en',
    name: 'null',
    organization: 'null',
    location: 'null',
    job: 'null',
    personaType: 'null',
    currentQuestionIndex: 'null',
    answers: [
        {
            question: 'null',
            answer: 'null',
        },
    ],
};

/**
 * Custom hook to handle API interactions for user data.
 * This hook provides CRUD operations for user data.
 *
 * @returns {{
 *  userData: User | null,
 *  isLoading: boolean,
 *  errorState: any,
 *  findUserByEmail: (email: string) => Promise<ApiResponse<User>>,
 *  updateUser: (userInfo: User) => Promise<ApiResponse<User>>,
 *  deleteUserByEmail: (email: string) => Promise<ApiResponse<void>>,
 *  getAllUsers: () => Promise<ApiResponse<User[]>>,
 *  setForceError: (force: boolean) => void,
 * }} - The user data, loading state, error state, and CRUD operations.
 */
export const useUserApi = () => {
    const [userData, setUserData] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { state: errorState, handleError } = useErrorScreen();
    const [forceError, setForceError] = useState<boolean>(false);
    const navigate = useNavigate();

    /**
     * Handles API requests with centralized error handling.
     *
     * @param {() => Promise<any>} apiCall - The API call function.
     * @returns {Promise<ApiResponse<any>>} - The API response data or error.
     */
    const handleApiRequest = useCallback(
        async (apiCall: () => Promise<any>): Promise<ApiResponse<any>> => {
            setIsLoading(true);
            handleError(null);

            try {
                if (forceError) {
                    throw new Error('Forced Error for Testing');
                }

                const response = await apiCall();
                return { data: response.data };
            } catch (err) {
                if (isAxiosError(err)) {
                    const serverError = err.response?.data || 'Unknown Error';
                    const statusCode = err.response?.status;

                    if (
                        err.code === 'ERR_INTERNET_DISCONNECTED' ||
                        !err.response
                    ) {
                        handleError(
                            new Error(
                                'Network Error: Please check your internet connection'
                            ),
                            undefined
                        );

                        navigate('/error');

                        return {
                            error: 'Network Error',
                            statusCode: undefined,
                        };
                    }

                    handleError(
                        new Error(JSON.stringify(serverError)),
                        statusCode
                    );
                    return {
                        error:
                            typeof serverError === 'string'
                                ? serverError
                                : JSON.stringify(serverError),
                        statusCode,
                    };
                } else {
                    handleError(new Error('Unexpected Error'), undefined);
                    navigate('/error');
                    return { error: 'Unexpected Error', statusCode: undefined };
                }
            } finally {
                setIsLoading(false);
            }
        },
        [handleError, forceError, navigate]
    );

    /**
     * Finds a user by email.
     *
     * @param {string} email - The email of the user to find.
     * @returns {Promise<ApiResponse<User> | null>} - The user data or error.
     */
    const findUserByEmail = useCallback(
        async (email: string): Promise<ApiResponse<User> | null> => {
            const sanitizedEmail = sanitizeInput(email);
            const url = `${apiUrl}/Users/find?email=${sanitizedEmail}`;

            // Sending Bearer Token for Authorization
            const { data, error, statusCode } = await handleApiRequest(() =>
                axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${uuid}`,
                    },
                })
            );

            if (error) {
                if (statusCode && statusCode !== 404) {
                    return null;
                }
            }

            setUserData(data);
            return { data: data };
        },
        [handleApiRequest]
    );

    /**
     * Updates user information.
     *
     * @param {User} userInfo - The updated user information.
     * @returns {Promise<ApiResponse<User> | null>} - The updated user data or error.
     */
    const updateUser = useCallback(
        async (userInfo: User): Promise<ApiResponse<User> | null> => {
            const sanitizedUserInfo = {
                ...userInfo,
                name: sanitizeInput(userInfo.name ?? ''),
                job: sanitizeInput(userInfo.job ?? ''),
                location: sanitizeInput(userInfo.location ?? ''),
                organization: sanitizeInput(userInfo.organization ?? ''),
                personaType: sanitizeInput(userInfo.personaType ?? ''),
                currentQuestionIndex: sanitizeInput(
                    userInfo.currentQuestionIndex ?? ''
                ),
                answers:
                    userInfo.answers?.map((answer: Answer) => ({
                        question: sanitizeInput(answer.question ?? ''),
                        answer: sanitizeInput(answer.answer ?? ''),
                    })) ?? defaultUser.answers,
            };

            const url = `${apiUrl}/Users/Update`;

            const response = await handleApiRequest(() =>
                axios.post(url, sanitizedUserInfo, {
                    headers: {
                        Authorization: `Bearer ${uuid}`,
                    },
                })
            );

            if (response) {
                return response;
            }

            return null;
        },
        [handleApiRequest]
    );

    /**
     * Deletes a user by email.
     *
     * @param {string} email - The email of the user to delete.
     * @returns {Promise<ApiResponse<void>>} - The response or error.
     */
    const deleteUserByEmail = useCallback(
        async (email: string): Promise<ApiResponse<void>> => {
            const sanitizedEmail = sanitizeInput(email);
            const url = `${apiUrl}/Users/Delete?email=${sanitizedEmail}`;

            return await handleApiRequest(() =>
                axios.delete(url, {
                    headers: {
                        Authorization: `Bearer ${uuid}`,
                    },
                })
            );
        },
        [handleApiRequest]
    );

    /**
     * Gets all users.
     *
     * @returns {Promise<ApiResponse<User[]>>} - The list of users or error.
     */
    const getAllUsers = useCallback(async (): Promise<ApiResponse<User[]>> => {
        const url = `${apiUrl}/Users/GetAllUsers`;

        return await handleApiRequest(() =>
            axios.get(url, {
                headers: {
                    Authorization: `Bearer ${uuid}`,
                },
            })
        );
    }, [handleApiRequest]);

    return {
        userData,
        isLoading,
        errorState,
        findUserByEmail,
        updateUser,
        deleteUserByEmail,
        getAllUsers,
        setForceError,
    };
};
