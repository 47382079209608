import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../Modal/Modal';
import Congratulations from '../Congratulations/Congratulations';
import Step from '../Step/Step';
import {
    StepListWrapper,
    PathImage,
    FlagImage,
    SVGWrapper,
    SVGPath,
} from './StepListStyles';
import gsap from 'gsap';
import { predefinedMarkers, predefinedPaths } from '../../utils/positions';
import { useTranslation } from 'react-i18next';
import { useAnimations } from '../../hooks/useAnimations';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { User } from '../../utils/types';

interface StepListProps {
    currentQuestionIndex: number;
    totalQuestions: number;
    onStepClick: (step: number) => void;
}

/**
 * StepList component renders a list of steps with paths and animations.
 *
 * @param {StepListProps} props - The props for the StepList component.
 * @param {number} props.currentQuestionIndex - The index of the current question.
 * @param {number} props.totalQuestions - The total number of questions.
 * @param {(step: number) => void} props.onStepClick - Function to call when a step is clicked.
 * @returns {JSX.Element} The rendered StepList component.
 */
const StepList: React.FC<StepListProps> = ({
    currentQuestionIndex,
    totalQuestions,
    onStepClick,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();
    const { pathRefs, flagRef, animateFlag, animatePath } = useAnimations();

    const { getLocalStorageItem } = useLocalStorage<User>('user', {} as User);
    const user = getLocalStorageItem();

    useEffect(() => {
        if (currentQuestionIndex === totalQuestions) {
            animateFlag();
        }
    }, [currentQuestionIndex, totalQuestions, animateFlag]);

    useEffect(() => {
        if (currentQuestionIndex > 0) {
            // Fade out all previous segments
            pathRefs.forEach((ref, i) => {
                if (i < currentQuestionIndex - 1 && ref.current) {
                    gsap.to(ref.current, { opacity: 0, duration: 0.3 });
                }
            });
            // Animate the current segment
            animatePath(currentQuestionIndex - 1);
        }
    }, [currentQuestionIndex, animatePath, pathRefs]);

    /**
     * Handles the click event for the final step, opening the modal.
     */
    const handleFinalStepClick = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    return (
        <>
            <StepListWrapper>
                <SVGWrapper width="300" height="700">
                    {predefinedMarkers.map((marker, index) => {
                        if (index < predefinedMarkers.length - 1) {
                            const nextMarker = predefinedMarkers[index + 1];
                            const pathData = `M${marker.left},${marker.top} L${nextMarker.left},${nextMarker.top}`;

                            return (
                                <SVGPath
                                    key={index}
                                    ref={pathRefs[index]}
                                    id={`path-${index}`}
                                    d={pathData}
                                    style={{
                                        opacity: 0,
                                    }}
                                />
                            );
                        }
                        return null;
                    })}
                </SVGWrapper>
                {predefinedMarkers.map((marker, index) => (
                    <React.Fragment key={index}>
                        {index <= currentQuestionIndex + 1 && (
                            <>
                                <PathImage
                                    src={predefinedPaths[index].src}
                                    alt={`Path from step ${index} to step ${
                                        index + 1
                                    }`}
                                    $enabled={index <= currentQuestionIndex}
                                    $top={predefinedPaths[index].top}
                                    $left={predefinedPaths[index].left}
                                    width={predefinedPaths[index].width}
                                    height={predefinedPaths[index].height}
                                />
                            </>
                        )}
                        <Step
                            index={index}
                            $enabled={index === currentQuestionIndex}
                            completed={index < currentQuestionIndex}
                            onClick={() => onStepClick(index + 1)}
                            position={{
                                top: `${marker.top}px`,
                                left: `${marker.left}px`,
                            }}
                        />
                    </React.Fragment>
                ))}
                {currentQuestionIndex === totalQuestions && (
                    <FlagImage
                        ref={flagRef}
                        src={`${window.location.origin}/images/various/abbott_flag.gif`}
                        alt="Abbott Flag"
                        onClick={handleFinalStepClick}
                    />
                )}
            </StepListWrapper>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={t(
                    `congratulations.${user?.personaType?.toLowerCase()}.title`
                )}
                centerAlign
            >
                <Congratulations />
            </Modal>
        </>
    );
};

export default React.memo(StepList);
