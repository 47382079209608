import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
    LanguageButton,
    LanguageDropdown,
    LanguageOption,
} from './LanguageSelectorStyles';
import { User } from '../../utils/types';

type LanguageCode =
    | 'en'
    | 'es'
    | 'ja'
    | 'zh'
    | 'nl'
    | 'fr'
    | 'de'
    | 'it'
    | 'pt'
    | 'ru'
    | 'vi';

const languageNames: Record<LanguageCode, string> = {
    en: 'English',
    es: 'Español',
    ja: '日本語',
    zh: '中文',
    nl: 'Nederlands',
    fr: 'Français',
    de: 'Deutsch',
    it: 'Italiano',
    pt: 'Português',
    ru: 'Русский',
    vi: 'Tiếng Việt',
};

/**
 * Type guard to check if a string is a valid LanguageCode.
 *
 * @param {string} lang - The language string to check.
 * @returns {lang is LanguageCode} True if the string is a valid LanguageCode, otherwise false.
 */
const isLanguageCode = (lang: string): lang is LanguageCode => {
    return Object.keys(languageNames).includes(lang);
};

interface LanguageSelectorProps {
    onLanguageChange: (language: LanguageCode) => void;
    resetLanguage: boolean;
    onResetComplete: () => void;
}

/**
 * LanguageSelector component allows the user to select a language from a dropdown menu.
 *
 * @param {LanguageSelectorProps} props - The props for the LanguageSelector component.
 * @param {(language: LanguageCode) => void} props.onLanguageChange - Function to call when the language is changed.
 * @param {boolean} props.resetLanguage - Boolean flag to trigger language reset to English.
 * @param {() => void} props.onResetComplete - Callback function to call when language reset is complete.
 * @returns {JSX.Element} The rendered LanguageSelector component.
 */
const LanguageSelector: React.FC<LanguageSelectorProps> = ({
    onLanguageChange,
    resetLanguage,
    onResetComplete,
}) => {
    const { i18n } = useTranslation();
    const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage<User>(
        'user',
        {} as User
    );
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const user = getLocalStorageItem();

    const initialLanguage: LanguageCode = isLanguageCode(i18n.language)
        ? (i18n.language as LanguageCode)
        : 'en';

    const userLanguage: LanguageCode | null =
        user && user.language && isLanguageCode(user.language)
            ? user.language
            : null;

    const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(
        userLanguage ?? initialLanguage
    );

    const dropdownRef = useRef<HTMLDivElement>(null);

    /**
     * Changes the language when a new language is selected.
     *
     * @param {LanguageCode} language - The language code to change to.
     */
    const changeLanguage = useCallback(
        (language: LanguageCode) => {
            i18n.changeLanguage(language);
            if (user) {
                const updatedUser = { ...user, language };
                setLocalStorageItem(updatedUser);
            }
            setSelectedLanguage(language);
            setDropdownOpen(false);
            onLanguageChange(language);
        },
        [i18n, setLocalStorageItem, user, onLanguageChange]
    );

    /**
     * Toggles the dropdown menu open/closed.
     */
    const toggleDropdown = useCallback(() => {
        setDropdownOpen((prevState) => !prevState);
    }, []);

    /**
     * Closes the dropdown menu when clicking outside of it.
     *
     * @param {MouseEvent} event - The mouse event triggered when clicking outside.
     */
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setDropdownOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        if (resetLanguage) {
            setSelectedLanguage('en');
            changeLanguage('en');
            onResetComplete();
        }
    }, [resetLanguage, changeLanguage, onResetComplete]);

    const languageOptions = useMemo(() => {
        return Object.entries(languageNames).map(([code, name]) => (
            <LanguageOption
                key={code}
                onClick={() => changeLanguage(code as LanguageCode)}
                $active={code === selectedLanguage}
            >
                {name}
            </LanguageOption>
        ));
    }, [changeLanguage, selectedLanguage]);

    return (
        <div style={{ position: 'relative' }} ref={dropdownRef}>
            <LanguageButton onClick={toggleDropdown}>
                {languageNames[selectedLanguage]}
            </LanguageButton>
            {dropdownOpen && (
                <LanguageDropdown>{languageOptions}</LanguageDropdown>
            )}
        </div>
    );
};

export default LanguageSelector;
