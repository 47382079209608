import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OptionButton from '../OptionButton/OptionButton';
import Button from '../Button/Button';
import {
    Wrapper,
    Question,
    ButtonsContainer,
} from './MultipleChoiceQuestionStyles';

interface MultipleChoiceQuestionProps {
    question: string;
    options: { [key: string]: string };
    handleAnswer: (answer: string) => void;
    stepNumber: number;
}

/**
 * MultipleChoiceQuestion component renders a multiple-choice question with options.
 *
 * @param {MultipleChoiceQuestionProps} props - The props for the MultipleChoiceQuestion component.
 * @param {string} props.question - The question to be displayed.
 * @param {{ [key: string]: string }} props.options - The options for the multiple-choice question.
 * @param {(answer: string) => void} props.handleAnswer - Function to handle the selected answer.
 * @param {number} props.stepNumber - The current step number of the quiz.
 * @returns {JSX.Element} The rendered MultipleChoiceQuestion component.
 */
const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
    question,
    options,
    handleAnswer,
    stepNumber,
}) => {
    const { t } = useTranslation();
    const [activeOption, setActiveOption] = useState<string | null>(null);

    useEffect(() => {
        setActiveOption(null);
    }, [stepNumber]);

    /**
     * Handles the click event for an option button.
     *
     * @param {string} optionKey - The key of the clicked option.
     */
    const handleOptionClick = useCallback((optionKey: string) => {
        setActiveOption(optionKey);
    }, []);

    /**
     * Handles the submit event for the selected option.
     */
    const handleSubmit = useCallback(() => {
        if (activeOption) {
            handleAnswer(activeOption);
        }
    }, [activeOption, handleAnswer]);

    return (
        <Wrapper>
            <Question>{t(question)}</Question>
            <ButtonsContainer>
                {Object.keys(options).map((key) => (
                    <OptionButton
                        key={key}
                        option={t(options[key])}
                        letter={key}
                        isActive={key === activeOption}
                        onClick={() => handleOptionClick(key)}
                    />
                ))}
            </ButtonsContainer>
            <Button onClick={handleSubmit} disabled={!activeOption}>
                {t(`quiz.steps.${stepNumber}.button`)}
            </Button>
        </Wrapper>
    );
};

export default MultipleChoiceQuestion;
