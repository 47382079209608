import React, { useCallback } from 'react';
import {
    ModalWrapper,
    ModalContent,
    CloseButton,
    ModalTitle,
} from './ModalStyles';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children?: React.ReactNode;
    noScroll?: boolean;
    width?: string;
    height?: string;
    centerAlign?: boolean;
}

/**
 * Modal component displays a modal dialog with a title, close button, and optional content.
 *
 * @param {ModalProps} props - The props for the Modal component.
 * @param {boolean} props.isOpen - Determines if the modal is open or closed.
 * @param {() => void} props.onClose - Function to call when the modal is closed.
 * @param {string} props.title - The title of the modal.
 * @param {React.ReactNode} [props.children] - The content to display inside the modal.
 * @param {boolean} [props.noScroll] - If true, disables scrolling inside the modal content.
 * @param {string} [props.width] - The width of the modal.
 * @param {string} [props.height] - The height of the modal.
 * @param {boolean} [props.centerAlign] - If true, aligns items in the center.
 * @returns {JSX.Element} The rendered Modal component.
 */
const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    title,
    children,
    noScroll,
    width,
    height,
    centerAlign,
}) => {
    /**
     * Handles click events inside the modal content to prevent propagation.
     *
     * @param {React.MouseEvent} e - The mouse event.
     */
    const handleContentClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);

    return (
        <ModalWrapper
            $isOpen={isOpen}
            $centerAlign={centerAlign}
            onClick={onClose}
        >
            <ModalContent
                onClick={handleContentClick}
                $noScroll={noScroll}
                $width={width}
                $height={height}
            >
                <CloseButton onClick={onClose} />
                <ModalTitle>{title}</ModalTitle>
                {children}
            </ModalContent>
        </ModalWrapper>
    );
};

export default React.memo(Modal);
