import React from 'react';
import { Button, Letter, OptionText } from './OptionButtonStyles';

interface OptionProps {
    option: string;
    letter: string;
    isActive: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * OptionButton component renders a button for a multiple-choice option.
 *
 * @param {OptionProps} props - The props for the OptionButton component.
 * @param {string} props.option - The text of the option.
 * @param {string} props.letter - The letter associated with the option.
 * @param {boolean} props.isActive - Indicates if the option is currently active/selected.
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} props.onClick - Function to call when the option is clicked.
 * @returns {JSX.Element} The rendered OptionButton component.
 */
const OptionButton: React.FC<OptionProps> = ({
    option,
    letter,
    isActive,
    onClick,
}) => {
    /**
     * Handles the click event for the button.
     *
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The mouse event triggered by clicking the button.
     */
    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.stopPropagation();
        onClick(event);
    };

    return (
        <Button $isActive={isActive} onClick={handleClick}>
            <Letter $isActive={isActive}>{letter}</Letter>
            <OptionText $isActive={isActive}>{option}</OptionText>
        </Button>
    );
};

export default React.memo(OptionButton);
