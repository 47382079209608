import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { User } from '../utils/types';

/**
 * Generates navigation conditions based on user data.
 *
 * @param {User} user - User object containing user data.
 * @returns {Array<{ condition: boolean, path: string }>} Array of navigation conditions.
 */
const getNavigationConditions = (user: any) => [
    {
        condition:
            !user.currentQuestionIndex &&
            (!user.name ||
                !user.job ||
                !user.personaType ||
                !user.organization ||
                !user.location),
        path: '/welcome',
    },
    {
        condition:
            user.currentQuestionIndex >= 0 &&
            user.currentQuestionIndex < 6 &&
            !!user.email &&
            !!user.name &&
            !!user.job &&
            !!user.personaType &&
            !!user.organization &&
            !!user.location,
        path: '/quiz',
    },
    {
        condition:
            user.currentQuestionIndex === 6 &&
            !!user.email &&
            !!user.name &&
            !!user.job &&
            !!user.personaType &&
            !!user.organization &&
            !!user.location,
        path: '/completed',
    },
];

/**
 * Custom hook to handle authentication-related redirects.
 *
 * @param {boolean} redirectTrigger - Trigger to initiate the redirect check.
 * @returns {boolean} Loading state indicating whether the redirect process is ongoing.
 */
export const useAuthRedirect = (redirectTrigger: boolean): boolean => {
    const navigate = useNavigate();
    const { getLocalStorageItem } = useLocalStorage<User>('user', {} as User);
    const user = getLocalStorageItem();

    const [isLoading, setIsLoading] = useState(true);
    const [hasNavigated, setHasNavigated] = useState(false);

    useEffect(() => {
        if (redirectTrigger && !hasNavigated) {
            const currentQuestionIndex = parseInt(
                user?.currentQuestionIndex ?? '',
                10
            );
            const normalizedUser = {
                ...user,
                currentQuestionIndex: isNaN(currentQuestionIndex)
                    ? null
                    : currentQuestionIndex,
            };
            const conditions = getNavigationConditions(normalizedUser);
            const matchedCondition = conditions.find(
                ({ condition }) => condition
            );

            if (matchedCondition) {
                navigate(matchedCondition.path);
            } else {
                navigate('/login');
            }

            setHasNavigated(true);
            setIsLoading(false);
        }
    }, [redirectTrigger, user, navigate, hasNavigated]);

    return isLoading;
};
