import styled from 'styled-components';

export const ButtonWrapper = styled.button<{ disabled?: boolean }>`
    display: flex;
    height: 3.5rem;
    padding: 0.75rem 3rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: ${({ disabled }) => (disabled ? '#D9D9D6' : '#009CDE')};
    color: ${({ disabled }) => (disabled ? '#888B8D' : '#FFF')};
    font-family: 'Brandon Grotesque Bold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.03125rem;
    border: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    text-transform: uppercase;
    margin: auto;

    &:hover {
        border-radius: 0.5rem;
        background: ${({ disabled }) => (disabled ? '#D9D9D6' : '#63666A')};
    }

    @media (max-width: 1024px) {
        padding: 0rem 2rem;
    }
`;
