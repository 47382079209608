import { useCallback } from 'react';
import { useEncryption } from './useEncryption';

/**
 * Custom hook for managing localStorage with optional encryption.
 *
 * @template T - The type of the value to be stored.
 * @param {string} key - The key under which the value is stored in localStorage.
 * @param {T} [initialValue] - The initial value to be stored if no value is found in localStorage.
 * @param {boolean} [encryptionEnabled=true] - Flag indicating whether encryption is enabled.
 * @returns {{
 *   getLocalStorageItem: () => T | null,
 *   setLocalStorageItem: (value: T) => void,
 *   removeLocalStorageItem: () => void,
 *   setMultipleLocalStorageItems: (items: Record<string, T>) => void,
 *   clearAllLocalStorageItems: () => void,
 * }} An object containing functions to manage localStorage.
 */
export const useLocalStorage = <T>(
    key: string,
    initialValue?: T,
    encryptionEnabled: boolean = false
) => {
    const { encrypt, decrypt } = useEncryption(encryptionEnabled);

    /**
     * Retrieves an item from localStorage.
     *
     * @returns {T | null} The retrieved item, or null if not found or an error occurred.
     */
    const getLocalStorageItem = useCallback((): T | null => {
        try {
            const item = window.localStorage.getItem(key);
            if (!item) return null;

            return encryptionEnabled
                ? (decrypt(item) as T)
                : (JSON.parse(item) as T);
        } catch (error) {
            console.error(`Error accessing localStorage key "${key}":`, error);
            return null;
        }
    }, [decrypt, encryptionEnabled, key]);

    /**
     * Sets an item in localStorage.
     *
     * @param {T} value - The item to store.
     */
    const setLocalStorageItem = useCallback(
        (value: T): void => {
            try {
                const item = encryptionEnabled
                    ? encrypt(value)
                    : JSON.stringify(value);

                window.localStorage.setItem(key, item);
            } catch (error) {
                console.error(
                    `Error setting localStorage key "${key}":`,
                    error
                );
            }
        },
        [encrypt, encryptionEnabled, key]
    );

    /**
     * Sets multiple items in localStorage.
     *
     * @param {Record<string, T>} items - An object containing key-value pairs to store.
     */
    const setMultipleLocalStorageItems = useCallback(
        (items: Record<string, T>): void => {
            try {
                Object.entries(items).forEach(([, value]) => {
                    setLocalStorageItem(value);
                });
            } catch (error) {
                console.error(
                    'Error setting multiple localStorage items:',
                    error
                );
            }
        },
        [setLocalStorageItem]
    );

    /**
     * Removes an item from localStorage.
     */
    const removeLocalStorageItem = useCallback(() => {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            console.error(`Error removing localStorage key "${key}":`, error);
        }
    }, [key]);

    /**
     * Clears all items from localStorage.
     */
    const clearAllLocalStorageItems = useCallback(() => {
        try {
            window.localStorage.clear();
        } catch (error) {
            console.error('Error clearing localStorage:', error);
        }
    }, []);

    return {
        getLocalStorageItem,
        setLocalStorageItem,
        removeLocalStorageItem,
        setMultipleLocalStorageItems,
        clearAllLocalStorageItems,
    };
};
