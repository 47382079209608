/**
 * Mapping of job titles to persona types.
 *
 * Each key in the object represents a job title, and the corresponding value is the persona type associated with that job title.
 *
 * @type {{ [key: string]: string }}
 */
const jobToPersonaMap: { [key: string]: string } = {
    Administrative: 'Supporter',
    'Business Support': 'Supporter',
    'Corporate Services': 'Supporter',
    'Customer Support': 'Connector',
    Facilities: 'Supporter',
    Finance: 'Supporter',
    'Human Resources': 'Supporter',
    IT: 'Facilitator',
    Legal: 'Supporter',
    Manufacturing: 'Creator',
    'Marketing and Communications': 'Connector',
    'Market Access': 'Connector',
    'Medical and Clinical Affairs': 'Facilitator',
    Operations: 'Creator',
    'Supply Chain': 'Connector',
    'Quality and Regulatory': 'Facilitator',
    'Research and Development': 'Creator',
    Sales: 'Connector',
    Other: 'Supporter',
};

export default jobToPersonaMap;
