import styled from 'styled-components';

export const LanguageButton = styled.button`
    display: inline-flex;
    padding: 0.75rem 1rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    border: 2px solid #d9d9d6;
    background: #fff;
    color: var(--Core-Dark-Blue, #002a3a);
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 450;
    line-height: 100%;
    letter-spacing: 0.0125rem;
    cursor: pointer;
    position: relative;
    margin: 5px;

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;

export const LanguageDropdown = styled.div`
    position: absolute;
    top: 50px;
    right: 10px;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 0.5rem;
`;

export const LanguageOption = styled.button<{ $active: boolean }>`
    display: block;
    padding: 10px;
    width: 100%;
    text-align: left;
    background: ${(props) => (props.$active ? '#e0e0e0' : 'none')};
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 450;
    line-height: 100%;
    letter-spacing: 0.0125rem;

    &:hover {
        background: #f0f0f0;
    }

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;
