import styled, { css } from 'styled-components';

interface ModalWrapperProps {
    $isOpen: boolean;
    $centerAlign?: boolean;
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background: rgba(0, 156, 222, 0.8);
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    justify-content: center;
    ${({ $centerAlign }) =>
        $centerAlign &&
        css`
            align-items: center;
        `}
    z-index: 1000;
    overflow: hidden;
`;

interface ModalContentProps {
    $noScroll?: boolean;
    $width?: string;
    $height?: string;
}

export const ModalContent = styled.div<ModalContentProps>`
    position: relative;
    display: flex;
    max-width: 47rem;
    max-height: 90vh;
    padding: 4rem 2rem 2rem 2rem;
    flex-direction: column;
    align-items: center;
    border-radius: 2.5rem;
    background: #fff;
    box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.2),
        0px 24px 38px 0px rgba(0, 0, 0, 0.14),
        0px 12px 56px 0px rgba(0, 0, 0, 0.12);
    overflow-x: hidden;
    overflow-y: ${({ $noScroll }) => ($noScroll ? 'hidden' : 'auto')};
    ${({ $width }) =>
        $width &&
        css`
            width: ${$width};
        `}
    ${({ $height }) =>
        $height &&
        css`
            height: ${$height};
        `}
    width: ${({ $width }) => $width || '100%'};
    height: ${({ $height }) => $height || 'auto'};
    max-height: 107vh;

    @media (max-width: 1024px) {
        transform: scale(0.75);
    }

    @media (min-width: 1025px) and (max-width: 1599px) {
        transform: scale(0.75);
    }

    @media (min-width: 1600px) {
        transform: scale(1);
    }
`;

export const CloseButton = styled.button`
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    background-image: url('${window.location
        .origin}/images/various/close-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        transform: scale(0.95);
    }
`;

export const ModalTitle = styled.div`
    position: relative;
    color: #222731;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-style: normal;
    font-weight: 450;
    text-transform: uppercase;
    line-height: 100%;
    display: flex;
    height: auto;
    padding: 1rem 1.5rem;
    margin: 1rem auto;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem 0rem;
    background: #ffd100;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        width: 125%;
        height: 2px;
        background-color: #ffd100;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateX(-10%);
        z-index: -1;
    }
`;
