import { useState, useCallback } from 'react';

/**
 * Interface representing the state of the error.
 *
 * @interface State
 * @property {boolean} hasError - Indicates whether an error has occurred.
 * @property {string} [errorMessage] - The error message, if an error has occurred.
 * @property {number | null} [errorCode] - The error code, if an error has occurred.
 */
interface State {
    hasError: boolean;
    errorMessage?: string | null;
    errorCode?: number | null;
}

/**
 * Custom hook to manage error boundaries in React components.
 *
 * @returns {{
 *   state: State,
 *   handleError: (error: Error | null, errorCode?: number | null) => void,
 * }} An object containing the error state and a function to handle errors.
 */
const useErrorScreen = () => {
    const [state, setState] = useState<State>({ hasError: false });

    /**
     * Handles errors by updating the error state.
     *
     * @param {Error | null} error - The error object or null.
     * @param {number | null} [errorCode] - The error code, if applicable.
     */
    const handleError = useCallback(
        (error: Error | null, errorCode: number | null = null) => {
            if (error) {
                setState({
                    hasError: true,
                    errorMessage: error.message,
                    errorCode,
                });
            }
        },
        []
    );

    return { state, handleError };
};

export default useErrorScreen;
