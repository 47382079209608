import styled, { keyframes } from 'styled-components';

const borderAnimation = keyframes`
    0% {
        border-image: linear-gradient(
            to top right,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    25% {
        border-image: linear-gradient(
            to bottom right,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    50% {
        border-image: linear-gradient(
            to bottom left,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    75% {
        border-image: linear-gradient(
            to top left,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
    100% {
        border-image: linear-gradient(
            to top right,
            rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
        ) 1;
    }
`;

const backgroundLanding = `${window.location.origin}/images/backgrounds/background_landing.svg`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(${backgroundLanding});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem;

    @media (max-width: 1024px) {
        padding: 2rem;
    }
`;

export const LanguageSelectorWrapper = styled.div`
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;

    @media (max-width: 1024px) {
        top: 0.75rem;
        right: 0.75rem;
    }
`;

export const Title = styled.div`
    color: #002a3a;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;
    align-self: flex-start;

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 480px;
`;

export const FormBox = styled.div`
    display: flex;
    width: 100%;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: relative;
    box-sizing: border-box;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        top: -1rem;
        right: -1rem;
        bottom: -1rem;
        left: -1rem;
        pointer-events: none;
        border: 6px solid transparent;
        border-image: linear-gradient(
                to top right,
                rgba(255, 209, 0, 1) 0%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 50%,
                rgba(255, 209, 0, 0) 100%
            )
            1;
        animation: ${borderAnimation} 5s linear infinite;
    }

    @media (max-width: 1024px) {
        width: 85%;
    }
`;

export const FormGroup = styled.div`
    margin: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .error-message {
        height: 2.25rem;
        margin-top: 0.5rem;
        align-self: start;
        color: #e4002b;
        font-family: 'Brandon Grotesque Regular';
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 390;
        line-height: 140%;
    }
`;

export const Input = styled.input<{ $hasError: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    width: 100%;
    flex-shrink: 0;
    align-self: stretch;
    padding: 1rem;
    border: 2px solid ${({ $hasError }) => ($hasError ? '#E4002B' : '#D9D9D6')};
    border-radius: 4px;
    font-size: 1.25rem;
    font-family: 'Brandon Grotesque Regular';
    font-weight: 420;
    line-height: 100%;
    color: #002a3a;

    &:hover {
        border-color: #009cde;
    }

    &:active {
        border-color: #002a3a;
    }

    &::placeholder {
        color: #63666a;
        font-family: 'Brandon Grotesque Regular';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 420;
        line-height: 100%;
    }

    @media (max-width: 1024px) {
        font-size: 100%;
        padding: 0.75rem;

        &::placeholder {
            font-size: 100%;
        }
    }
`;
