import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { ErrorWrapper, ErrorTitle, ButtonWrapper } from './ErrorScreenStyles';
import useErrorScreen from '../../hooks/useErrorScreen';

/**
 * ErrorScreen component displays an error message and a "TRY AGAIN" button
 * when an error occurs.
 *
 * @returns {JSX.Element} The rendered ErrorScreen component.
 */
const ErrorScreen: React.FC = () => {
    const { state } = useErrorScreen();
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**
     * Handles the click event for the "TRY AGAIN" button.
     * Redirects the user to the /login page using the useNavigate hook.
     */
    const handleBackClick = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        if (state.hasError) {
            console.error(state.errorMessage);
        }
    }, [state]);

    return (
        <ErrorWrapper>
            <ErrorTitle>{t('login.error.anErrorOccurred')}</ErrorTitle>
            <ButtonWrapper>
                <Button onClick={handleBackClick}>
                    {t('login.error.tryAgain')}
                </Button>
            </ButtonWrapper>
        </ErrorWrapper>
    );
};

export default ErrorScreen;
