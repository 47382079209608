import styled from 'styled-components';

const backgroundCompleted = `${window.location.origin}/images/backgrounds/background_metrics_8K.png`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${backgroundCompleted});
    background-position: center;
    background-repeat: no-repeat;
    overflow-y: auto;

    @media (max-width: 768px) {
        background-size: 380rem;
    }

    @media (min-width: 769px) {
        background-size: 270rem;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem;
    width: 100%;
`;

export const AbbottLogo = styled.img`
    position: relative;
    width: 7.5rem;
    height: auto;

    @media (max-width: 1024px) {
        width: 6rem;
    }
`;

export const Title = styled.div`
    font-family: 'Brandon Grotesque Bold';
    color: #002a3a;
    font-size: 130%;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    text-transform: uppercase;
    text-align: center;
    position: relative;

    @media (max-width: 768px) {
        text-align: right;
        max-width: 10rem;
    }
`;

export const CongratulationsWrapper = styled.div`
    max-width: 40rem;
    margin: 8rem auto 0rem auto;
    width: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    transform: scale(0.8);

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const PersonaTitle = styled.div`
    position: relative;
    color: #222731;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 450;
    text-transform: uppercase;
    line-height: 100%;
    display: flex;
    height: 2.5rem;
    padding: 1rem 1.5rem;
    margin: 1rem auto;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem 0rem;
    background: #ffd100;
    z-index: 1;

    @media (max-width: 1024px) {
        font-size: 75%;
    }

    &::before {
        content: '';
        position: absolute;
        width: 125%;
        height: 2px;
        background-color: #ffd100;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateX(-10%);
        z-index: -1;
    }
`;
