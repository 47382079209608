import styled, { css } from 'styled-components';

export const backgroundLanding = `${window.location.origin}/images/backgrounds/mountain_bg_8K.png`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 2rem 1.5rem;
    position: relative;
    overflow: hidden;
`;

export const BackgroundImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    pointer-events: none;
    width: 100vw;
    height: auto;

    @media (max-width: 470px) {
        height: 896px;
        transform: scale(3);
        top: -50px;
    }

    @media (min-width: 471px) and (max-width: 1440px) {
        height: 812px;
        transform: scale(4);
        top: -60px;
    }

    @media (min-width: 1441px) {
        height: 1650px;
        transform: scale(1);
        top: -20rem;
        object-fit: none;
    }
`;

export const Overlay = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1500;
`;

export const HeaderContainer = styled.div<{ $isFirstStep: boolean }>`
    width: 100%;
    gap: 2.5rem;

    ${(props) =>
        !props.$isFirstStep &&
        css`
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        `}
`;

export const AbbottLogo = styled.img`
    position: relative;
    width: 6.5rem;
    height: auto;
`;

export const TitleContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
    top: 0;
    left: 0;

    @media (max-width: 1024px) {
        position: relative;
        padding: 0;
    }
`;

export const Title = styled.h1`
    color: #002a3a;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    text-transform: uppercase;
    position: relative;
    font-size: 1.5rem;

    @media (max-width: 768px) {
        text-align: right;
        font-size: 1.5rem;
    }

    ${TitleContainer} & {
        @media (max-width: 768px) {
            margin-top: 2rem;
            font-size: 1.8rem;
            text-align: center;
        }
    }
`;

export const Subtitle = styled.h2`
    color: #002a3a;
    text-align: center;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 420;
    line-height: 110%;
    margin-top: 1rem;

    @media (min-width: 200px) {
        font-size: 100%;
    }
`;

export const AbbottProudContainer = styled.div`
    position: fixed;
    bottom: 2rem;
    left: 4rem;
    display: flex;
    text-transform: uppercase;

    @media (max-width: 1024px) {
        bottom: 1rem;
        left: 1.5rem;
        font-size: 100%;
    }
`;

export const AbbottPart = styled.div`
    color: #004f71;
    font-family: 'Brandon Grotesque Regular';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 390;
    line-height: 120%;

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;

export const ProudPart = styled.div`
    color: #004f71;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    @media (max-width: 1024px) {
        font-size: 100%;
    }
`;
