import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './globalStyles';
import WebFont from 'webfontloader';

const container = document.getElementById('root');
if (!container) {
    throw new Error('Failed to find the root element');
}

const root = ReactDOM.createRoot(container);

/**
 * Load custom fonts using Web Font Loader.
 *
 * @see https://github.com/typekit/webfontloader
 */
WebFont.load({
    custom: {
        families: ['Brandon Grotesque Regular', 'Brandon Grotesque Bold'],
        urls: [
            `${window.location.origin}/fonts/BrandonGrotesque/BrandonGrotesque.css`,
        ],
    },
    active: () => {
        root.render(
            <React.StrictMode>
                <GlobalStyles />
                <App />
            </React.StrictMode>
        );
    },
});

/**
 * Measure and report web vitals for performance analytics.
 *
 * @see https://bit.ly/CRA-vitals
 */
reportWebVitals();
