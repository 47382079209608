import styled from 'styled-components';

interface ButtonProps {
    $isActive: boolean;
}

export const Button = styled.button<ButtonProps>`
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 2px solid ${({ $isActive }) => ($isActive ? '#002A3A' : '#d9d9d6')};
    background: ${({ $isActive }) => ($isActive ? '#002A3A' : '#FFF')};
    cursor: pointer;
    width: 100%;
    margin-bottom: 1rem;

    &:hover {
        border: ${({ $isActive }) =>
            $isActive ? '2px solid #002A3A' : '2px solid #009CDE'};
        background: ${({ $isActive }) => ($isActive ? '#002A3A' : '#FFF')};
    }
`;

export const Letter = styled.div<ButtonProps>`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1.75rem;
    border: ${({ $isActive }) => ($isActive ? 'none' : '2px solid #009cde')};
    background: #fff;
    color: #002a3a;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.025rem;

    @media (max-width: 1024px) {
        width: 2rem;
        height: 2rem;
        font-size: 150%;
    }
`;

export const OptionText = styled.div<ButtonProps>`
    color: ${({ $isActive }) => ($isActive ? '#FFF' : '#002a3a')};
    font-family: 'Brandon Grotesque Regular';
    font-size: 150%;
    font-style: normal;
    font-weight: 390;
    line-height: 120%;
    text-align: left;

    @media (max-width: 1024px) {
        font-size: 150%;
        max-width: 70%;
    }
`;
