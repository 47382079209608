import styled, { keyframes, css } from 'styled-components';

// Keyframes for the progress bar animation
const fillAnimation = keyframes`
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;

    @media (max-width: 1024px) {
        width: 70%;
    }
`;

export const Logo = styled.img`
    width: 9.75rem;
    height: 10.625rem;
    flex-shrink: 0;
`;

export const ProgressBarContainer = styled.div.attrs({
    role: 'progressbar',
})`
    width: 100%;
    max-width: 21rem;
    height: 0.9375rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: #d9d9d9;
    overflow: hidden;
`;

export const ProgressBarFilled = styled.div<{ $duration: number }>`
    height: 100%;
    border-radius: 0.5rem;
    background: #009cde;
    animation: ${({ $duration }) =>
        css`
            ${fillAnimation} ${$duration}ms linear forwards
        `};
`;

export const LoadingText = styled.div`
    color: #009cde;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    opacity: 0.5;
`;
