import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Brandon Grotesque Regular';
        src: url('${window.location.origin}/fonts/BrandonGrotesque/BrandonGrotesque-Regular.otf');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Brandon Grotesque Bold';
        src: url('${window.location.origin}/fonts/BrandonGrotesque/BrandonGrotesque-Bold.otf');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body, #root {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 16px;
    }

    body {
        font-family: 'Brandon Grotesque Regular', Arial, sans-serif;
        line-height: 1.5;
        color: #000;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-family: 'Brandon Grotesque Bold', sans-serif;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    ul, ol {
        list-style: none;
    }

    img {
        max-width: 100%;
        display: block;
    }

    button {
        background: none;
        border: none;
        padding: 0;
        font-family: inherit;
        cursor: pointer;
    }

    input, select, textarea {
        font-family: inherit;
        font-size: inherit;
    }
`;

export default GlobalStyles;
