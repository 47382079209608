import { useState, useCallback, useMemo } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from './useLocalStorage';
import { useUserApi } from './useUserApi';
import { User } from '../utils/types';

type Answer = {
    question: string;
    answer: string;
};

type QuizQuestion = {
    question: string;
    options: { [key: string]: string };
    button: string;
    feedback: {
        title: string;
        description: string;
    };
};

interface UseQuizLogicReturn {
    currentQuestionIndex: number | null;
    answers: Answer[];
    isModalOpen: boolean;
    showFeedback: boolean;
    feedbackMessages: string[];
    handleAnswer: (answerKey: string) => void;
    handleNextQuestion: () => void;
    setIsModalOpen: (isOpen: boolean) => void;
    setShowFeedback: (show: boolean) => void;
    handleCloseFeedback: () => void;
}

/**
 * Custom hook to manage quiz logic including question navigation and feedback handling.
 *
 * @returns {UseQuizLogicReturn} An object containing quiz state and handlers for user interaction.
 */
export const useQuizLogic = (): UseQuizLogicReturn => {
    const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage<User>(
        'user',
        {} as User
    );
    const user = getLocalStorageItem();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);

    const { updateUser } = useUserApi();

    const { t } = useTranslation();
    let currentQuestionIndex = parseInt(user?.currentQuestionIndex ?? '', 10);

    const answers: Answer[] = useMemo(() => {
        return (user?.answers ?? []).map((answer) => ({
            question: answer.question ?? '',
            answer: answer.answer ?? '',
        }));
    }, [user]);

    /**
     * Gets the English translation of a given key.
     *
     * @param {string} key - The key to translate.
     * @returns {any} The English translation of the key.
     */
    const getEnglishTranslation = useCallback((key: string) => {
        return i18next.getFixedT('en')(key, { returnObjects: true });
    }, []);

    /**
     * Handles the user's answer to a quiz question.
     *
     * @param {string} answerKey - The key of the selected answer.
     */
    const handleAnswer = useCallback(
        (answerKey: string) => {
            const currentQuestionKey = `quiz.steps.${currentQuestionIndex + 1}`;
            const currentQuestion = t(currentQuestionKey, {
                returnObjects: true,
            }) as QuizQuestion;
            const englishQuestion = getEnglishTranslation(
                currentQuestionKey
            ) as QuizQuestion;

            if (!currentQuestion?.question || !currentQuestion.options) {
                console.error('Invalid question structure:', currentQuestion);
                return;
            }

            const englishAnswer = englishQuestion.options[answerKey];
            if (!englishAnswer) {
                console.error('Invalid answer option:', answerKey);
                return;
            }

            const answerObject: Answer = {
                question: englishQuestion.question,
                answer: englishAnswer,
            };

            const updatedAnswers = [...answers];
            updatedAnswers[currentQuestionIndex] = answerObject;

            const updatedUser = {
                ...user,
                answers: updatedAnswers,
            };
            setLocalStorageItem(updatedUser);

            setShowFeedback(true);
            setIsModalOpen(false);
        },
        [
            answers,
            currentQuestionIndex,
            getEnglishTranslation,
            setLocalStorageItem,
            t,
            user,
        ]
    );

    /**
     * Handles the transition to the next quiz question.
     */
    const handleNextQuestion = useCallback(async () => {
        setShowFeedback(false);
        const nextQuestionIndex = (currentQuestionIndex + 1).toString();

        try {
            const updatedUser = {
                ...user,
                currentQuestionIndex: nextQuestionIndex,
            };
            const result = await updateUser(updatedUser);

            if (result) {
                setLocalStorageItem(updatedUser);
            } else {
                console.error(
                    'Failed to update user: updateUser returned null'
                );
            }
        } catch (error) {
            console.error('Failed to update user:', error);
        }
    }, [currentQuestionIndex, user, updateUser, setLocalStorageItem]);

    /**
     * Handles closing the feedback modal.
     */
    const handleCloseFeedback = useCallback(() => {
        setShowFeedback(false);
        setIsModalOpen(false);
    }, []);

    const feedbackMessages: string[] = useMemo(
        () => [
            t('quiz.feedback.0'),
            t('quiz.feedback.1'),
            t('quiz.feedback.2'),
            t('quiz.feedback.3'),
            t('quiz.feedback.4'),
            t('quiz.feedback.5'),
        ],
        [t]
    );

    return {
        currentQuestionIndex,
        answers,
        isModalOpen,
        showFeedback,
        feedbackMessages,
        handleAnswer,
        handleNextQuestion,
        setIsModalOpen,
        setShowFeedback,
        handleCloseFeedback,
    };
};
